
import React from 'react';
import ReactDOM from 'react-dom';
import Demo from './demo';
import Menu from './menu';
// import * as React from 'react';
// import ReactDOM from 'react-dom/client';
// import { StyledEngineProvider } from '@mui/material/styles';
// import Demo from './demo';
ReactDOM.render(<Menu />, document.querySelector('#menu'));
ReactDOM.render(<Demo />, document.querySelector('#root'));
// ReactDOM.createRoot(document.querySelector("#root")).render(
//     <React.StrictMode>
//       <StyledEngineProvider injectFirst>
//         <Demo />
//       </StyledEngineProvider>
//     </React.StrictMode>
//   );