import Button from '@mui/material/Button';
import { DataGrid, GridColDef, GridApi, GridCellValue, GridToolbar, GridRenderCellParams } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
// import { useDemoData } from '@mui/x-data-grid-generator';
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Chip, { ChipProps } from "@material-ui/core/Chip";
import { red, blue, orange } from "@material-ui/core/colors";

function getChipProps(params: GridRenderCellParams): ChipProps {
  if (params.value === "Dropped") {
    return {
      icon: <WarningIcon style={{ fill: orange[500] }} />,
      label: params.value,
      style: {
        borderColor: red[500]
      }
    };
  } else {
    return {
      icon: <CheckCircleIcon style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: blue[500]
      }
    };
  }
}

const columns: GridColDef[] = [
//   {
//     field: 'action',
//     headerName: 'Action',
//     sortable: false,
//     renderCell: (params) => {
//       const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
//         e.stopPropagation(); // don't select this row after clicking

//         const api: GridApi = params.api;
//         const thisRow: Record<string, GridCellValue> = {};

//         api
//           .getAllColumns()
//           .filter((c) => c.field !== '__check__' && !!c)
//           .forEach(
//             (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
//           );

//         return alert(JSON.stringify(thisRow, null, 4));
//       };

//       return <Button onClick={onClick}>Click</Button>;
//     },
//   },
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'title', headerName: 'Title', width: 130, editable: true },
  { field: 'category', headerName: 'Category', width: 130 },
  { field: 'status', headerName: 'Status', width: 130, editable: true, 
    renderCell: (params) => {
      return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
  } },
  { field: 'last_date', headerName: 'Completion Date', width: 130 },
  { field: 'rating', headerName: 'Rating', width: 130 },
  { field: 'adjusted', headerName: 'Adjusted Rating', width: 130 },
  // {
  //   field: 'age',
  //   headerName: 'Age',
  //   type: 'number',
  //   width: 90,
  // },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.getValue(params.id, 'firstName') || ''} ${
  //       params.getValue(params.id, 'lastName') || ''
  //     }`,
  // },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function DataGridDemo() {
  const [tableData, setTableData] = useState([])
  const url = "https://78yl1us7q1.execute-api.ap-southeast-2.amazonaws.com/default/entertainment_get"

  useEffect(() => {
    fetch(url, {
        method: "GET",
        headers: {
          'X-Api-Key': '29BZrBZdTS73aFVrkaFrD9mZ9q2HDsQD62Jichnq',
          "Content-Type": "application/json"
        }
      }
    )
      .then((data) => data.json())
      .then((data) => setTableData(data))
  }, [])

  console.log(tableData)

  // const { data } = useDemoData({
  //   dataSet: 'Commodity',
  //   rowLength: 100,
  // });

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          height: 400,
          width: '100%',
        }}
      >
      <DataGrid 
        rows={tableData}
        columns={columns}
        pageSize={15}
        checkboxSelection 
        components={{ Toolbar: GridToolbar }} 
        componentsProps={
            { toolbar: {
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
            } }
        }
        initialState={{
          sorting: {
            sortModel: [{ field: 'last_date', sort: 'asc' }],
          },
        }}
        getRowClassName={(params) => `super-app-theme--${params.row.status}`}
      />
    </Box>
    </div>
  );
}
